/* global Component, _ , moment, axios , __lodash__*/
class CarDeliveryListComponentB2C extends Component {

    static name() {
        return "carDeliveryList";
    }

    static componentName() {
        return "carDeliveryList";
    }

    data() {
        return {
            selectedCode: null,
            searchText: null,
            Comment: null,
            loadding: true,
            selectedCC: null,
            newDeliveryModal: false,
            commentSt: "",
            commentSO: "",
            deliveryListState: [],
            selectedShipMode: null,
            shipDateOffset: {},
        };
    }

    getWatch() {
        return {
            "$store.state.order_DelAddressCode": function (newValue, olValue) {
                if (newValue != this.selectedCode)
                    this.selectedCode = newValue;
            },
            'selectedShipMode': function (newValue, oldValue) {
                this.emitEvent("shipModeChange", newValue);
            },
            "deliveryList": function (newValue, oldValue) {
                if (newValue && newValue.length == 1 && !this.selectedCode) {
                    this.selectedCode = newValue[0].Code;
                    this.$store.dispatch('updateOrderDeliveryData', {"order_DelAddressCode": newValue[0].Code});
                    this.activeAddress(this.selectedCode);
                }
            },
        };
    }

    created() {
        return async function () {
            this.loadCustomerAddresses();
        };
    }

    mounted() {
        return async function () {
            if (!this.selectedCode)
                this.selectedCode = this.$store.state.order_DelAddressCode;
            else {
                if (this.deliveryList.length > 0)
                    this.selectedCode = this.deliveryList[0].Code;
            }
            if (!this.selectedShipMode) {
                if (this.$store.state.order_ShipMode) {
                    this.selectedShipMode = this.$store.state.order_ShipMode;
                } else if (this.shipModes.length > 0) {
                    this.selectedShipMode = this.shipModes[0].ShipModeCode;
                }


            }


            let eventObj = {
                order_DelAddressCode: this.selectedCode,
                order_ShipMode: this.selectedShipMode,
                order_ReceptionManagerCode: this.$store.getters.getSession.fields.Code
            };
            this.$store.dispatch('updateOrderDeliveryData', eventObj);
            this.subscribeEvent("selectcentercost", this.centerCostCallback);
            this.subscribeEvent('checkout-step-back-2', this.resetShipCost);

        };
    }

    getComputed() {
        return {
            showCommentSO: function () {
                return this.$store.state.showCommentSO;
            },
            showCommentSt: function () {
                return this.$store.state.showCommentSt;
            },
            renderCommentSection: function () {
                return this.showCommentSO || this.showCommentSt;
            },
            orderTemplate() {
                return __lodash__.cloneDeep(this.$store.state.carOrderTemplate);
            },
            shipModes: function () {
                let modes = this.$store.getters.getShipModes;
                let so = this.orderTemplate.fields;
                for (let mode of modes) {
                    if(mode.CostPerRank) {
                        let cls = mode.CostPerRank.fields;
                        let tot;
                        switch (cls.Model) {
                            case 0:
                                tot = so.TotQty;
                                break;
                            case 1:
                                tot = so.Total;
                                break;
                            case 2: 
                                tot = so.TotWeight;
                                break;
                            case 3: 
                                tot = so.TotVolume;
                                break;
                        }
                        for (let rate of cls.Costs) {
                            if(tot <= rate.fields.UpToModel) {
                                mode.Cost = rate.fields.Price;
                                break;
                            }
                        }
                    }
                } 
                return modes;
            },
            canAddNew: function () {
                return (this.isAdmin || this.deliveryList.length === 0) && !this.loadding;
            },
            filterDeliveryList: function () {
                let searchText = this.searchText;
                if (!searchText || searchText === '')
                    return this.deliveryList;
                else
                    return _.filter(this.deliveryList, function (delivery) {
                        let reg = new RegExp(`${searchText}`, 'i');
                        return String(delivery.Name).match(reg) || String(delivery.Address).match(reg) || String(delivery.City).match(reg) || String(delivery.Province).match(reg) || String(delivery.ZipCode).match(reg);
                    });
            },
            deliveryList: function () {
                return this.$store.getters.getCustomerAddresses;
            },
            centerCostFilter: function () {
                if (this.selectedCC) {
                    //console.log("Selected CC ",this.selectedCC.internalId)
                    return {masterId: this.selectedCC.internalId};
                }
                return {masterId: "-99999"};
            },
            deliveAddressByCustomer: function () {
                return this.$store.state.customer.DelAddressCode;
            },
            activeDayByZone: function () {
                let selectedShipMode = this.selectedShipMode;
                let shipMode = __lodash__.find(this.$store.state.shipModes, function (shipMode) {
                    return shipMode.ShipModeCode === selectedShipMode;
                });

                if (shipMode && shipMode.OfficeCode)
                    return false;

                let dayMap = this.$store.getters.getDaysByZone;
                for (let day of Object.keys(dayMap)) {
                    if (dayMap[day])
                        return true;
                }
                return false;
            },
            daysByZone: function () {
                return this.$store.getters.getDaysByZone;
            },
            shipItemCode: function () {
                return this.$store.getters.getShipItemCode;
            },
        };
    }

    resetShipCost() {
        this.selectedShipMode = null;
        this.emitEvent("shipModeChange", this.selectedShipMode);
    }

    loadCustomerAddresses() {
        const self = this;
        this.$store.dispatch('toogleLockScreen', null);
        this.$store.dispatch('loadCustomerAddresses').then(() => {
            if (!this.selectedCode && self.filterDeliveryList.length > 0)
                self.activeAddress(self.filterDeliveryList[0].Code);
            self.$store.dispatch('toogleLockScreen', null);
        });
    }

    activeDeliveryAddress(delCode) {
        if (this.selectedCode != null) {
            if (this.selectedCode === delCode)
                return true;
        } else {
            if (this.deliveAddressByCustomer === delCode) {
                this.activeAddress(delCode);
                return true;
            }
        }
        return false;
    }

    activeAddress(code) {
        this.selectedCode = code;
        $("#resetPoint").scrollTop(0);
        this.$store.dispatch('updateOrderDeliveryData', {'order_DelAddressCode': code});
        this.emitEvent('deliveryselected', {'order_DelAddressCode': code});
    }

    buildComment() {
        let comment = "";
        if (this.commentSt)
            comment = `Aclaración del Domicilio:\n${this.commentSt}\n \n`;
        if (this.commentSO)
            comment = `${comment}Aclaración del Pedido:\n ${this.commentSO}.`;
        let eventObj = {};
        eventObj[`order_Comment`] = comment;
        this.$store.dispatch('updateOrderDeliveryData', eventObj);
    }

    updateSelectValues(updateValue) {
        //console.log('Casdadasd',updateValue,event.target.value);
        let eventObj = {};
        eventObj[`order_${updateValue}`] = event.target.value;
        this.$store.dispatch('updateOrderDeliveryData', eventObj);
    }

    openNewDelivery() {
        this.$emit('new-delivery');
    }

    createNewDelivey(payload) {
        return new Promise((resolve, reject) => {
            let self = this;
            payload['EntityType'] = 0;
            payload['Entity'] = this.$store.state.customer.Code;
            payload['EntityName'] = this.$store.state.customer.Name;
            axios.post('/ecommerce/api/saveRecord', {
                recordName: 'CompanyAddress',
                payload: payload
            })
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.success) {
                        self.deliveryList.push(response.data.record.fields);
                        self.activeAddress(response.data.record.fields.Code);
                    }
                    resolve(response.data);
                });
        });
    }

    openNewDeliveryModal() {
        this.newDeliveryModal = true;
        setTimeout(() => {
            $("#newDeliveryModal").modal('show');
        }, 500);
        //$("#newDeliveryModal").modal('show');
    }

    centerCostCallback(event) {
        this.selectedCC = event;
        this.selectedCode = event.DelAddressCode;
        this.activeAddress(event.DelAddressCode);
    }

    calculateDate(days, shipmode) {
        if (!shipmode)
            return null;
        moment.locale('en');
        if (this.shipDateOffset[shipmode.ShipModeCode]) {
            days = days + this.shipDateOffset[shipmode.ShipModeCode];
        }
        let date = moment().add(days, 'days');
        let iterator = 0;
        if (!shipmode.ShipDays[date.format("dddd")]) {
            while (!shipmode.ShipDays[date.format("dddd")] && iterator < 7) {
                date.add(1, 'days');
                iterator += 1;
            }
            if (iterator == 7)
                date = moment().add(days, 'days');
            else {
                this.shipDateOffset[shipmode.ShipModeCode] = iterator;
            }
        }
        date.locale(this.$store.state.setting.DefaultLanguage);
        return moment(date).format("dddd DD-MMM");
    }

    isFreeShipCost(freeShipCost) {
        if (this.orderTemplate && this.orderTemplate.fields.Items && this.shipItemCode) {
            let items = __lodash__.cloneDeep(this.orderTemplate.fields.Items);
            let shipModeCodes = this.shipItemCode.split(",");
            let shipCostItem = __lodash__.find(items, function (cost) {
                return shipModeCodes.indexOf(cost.fields.ArtCode) !== -1;
            });
            if (shipCostItem) {
                if (this.$store.getters.getSetting.ShowPriceWithIVA)
                    return this.orderTemplate.fields.Total - shipCostItem.fields.VATPrice >= freeShipCost;
                else
                    return this.orderTemplate.fields.SubTotal - shipCostItem.fields.Price >= freeShipCost;
            }
        }
        if (this.orderTemplate) {
            if (this.$store.getters.getSetting.ShowPriceWithIVA)
                return this.orderTemplate.fields.Total >= freeShipCost;
            else
                return this.orderTemplate.fields.SubTotal >= freeShipCost;
        }
        return false;
    }

    getMethods() {
        return {
            resetShipCost: this.resetShipCost,
            isFreeShipCost: this.isFreeShipCost,
            loadCustomerAddresses: this.loadCustomerAddresses,
            activeDeliveryAddress: this.activeDeliveryAddress,
            activeAddress: this.activeAddress,
            buildComment: this.buildComment,
            updateSelectValues: this.updateSelectValues,
            openNewDelivery: this.openNewDelivery,
            createNewDelivey: this.createNewDelivey,
            openNewDeliveryModal: this.openNewDeliveryModal,
            centerCostCallback: this.centerCostCallback,
            calculateDate: this.calculateDate
        };
    }

    getTemplate() {
        return `<div class="col-12">
                  <template v-if="newDeliveryModal">
                    <addressCreateComponent />
                  </template>
                  <div class="col-12" >
                    <div class="mt-3 bordered reset-point" id="resetPoint">
                         <div class="row m-0 align-items-center">
                            <h6 class="col-12 col-lg-6 align-items-center d-flex"><i class="delivery-icon fas fa-address-card fa-2x mr-3"></i>{{tr('Select a Delivery Address')}}</h6>
                            <div class="col-12 col-lg-6 text-left text-lg-right">
                                <div class="my-2" v-on:click="openNewDelivery">
                                    <button type="button" class="btn btn-light" @click="openNewDeliveryModal">
                                        <i class="fas fa-plus mr-2"></i>
                                        {{tr('New Delivery Address')}}
                                   </button>
                                </div>
                            </div>
                        </div>
                        <div v-if="filterDeliveryList.length > 1" class="row m-0 align-items-start">
                            <div class="input-group col-12 col-lg-6">
                                <input class="form-control" v-model="searchText">
                                <div class="input-group-prepend">
                                  <div class="input-group-text"><i class="fa fa-search" /></div>
                                </div>
                            </div>
                        </div>
                        <div v-if="filterDeliveryList.length > 0" class="row m-0 px-2">
                             <template v-for="delObj in filterDeliveryList">
                                 <template v-if="delObj.Code==selectedCode">
                                    <div class="col-12 col-sm-6 col-lg-4 px-2">
                                        <div class="address-card my-2" :class="{selected:activeDeliveryAddress(delObj.Code)}" v-on:click="activeAddress(delObj.Code)" >
                                          <strong id="deliveryLabel"><i class="delivery-icon fas fa-map-marker-alt mr-1"></i>{{delObj.Name}}</strong>
                                          <p>{{delObj.Address}} </p>
                                          <p>{{delObj.Province}} </p>
                                          <p>{{delObj.City}} </p>
                                          <p>{{delObj.Locality}} </p>
                                          <p>{{delObj.ZipCode}} </p>
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <template v-for="delObj in filterDeliveryList">
                                <template v-if="delObj.Code!=selectedCode">
                                    <div class="col-12 col-sm-6 col-lg-4 px-2">
                                        <div class="address-card my-2" :class="{selected:activeDeliveryAddress(delObj.Code)}" v-on:click="activeAddress(delObj.Code)" >
                                          <strong id="deliveryLabel"><i class="delivery-icon fas fa-map-marker-alt mr-1"></i>{{delObj.Name}}</strong>
                                          <p>{{delObj.Address}} </p>
                                          <p>{{delObj.Province}} </p>
                                          <p>{{delObj.City}} </p>
                                          <p>{{delObj.Locality}} </p>
                                          <p>{{delObj.ZipCode}} </p>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                        <div class="row new-address-message text-left text-md-right m-0">
                            <p class="col-12 mb-1">Puede administrar las direcciones en <router-link to="/profile/Addresses">Perfil Direcciones</router-link></p>
                        </div>
                    </div>
                    <div class="bordered form-row m-0 mb-2 px-2" v-if="shipModes.length>0">
                        <h6 class="col-12 align-items-center d-flex mb-3"><i class="delivery-icon fas fa-truck fa-flip-horizontal fa-2x mr-3" style="font-size: 1.6em;"></i>{{tr('Ship Mode')}}</h6>
                        <template v-for="shipMode of shipModes" >
                            <div :key="'shipMode-'+shipMode.ShipModeCode" class="col-12 col-lg-6 px-2">
                                <div class="address-card row m-0 my-2" :class="{selected:selectedShipMode==shipMode.ShipModeCode}" v-on:click="selectedShipMode=shipMode.ShipModeCode">
                                  <div class="col-12" >
                                      <strong id="deliveryLabel"><i class="delivery-icon fas fa-truck fa-flip-horizontal mr-2"></i>{{shipMode.ShipModeName}}</strong>
                                      <p>
                                        {{tr('Cost')}}: 
                                        <template v-if="shipMode.FreeShipMinAmount">
                                            <template v-if="isFreeShipCost(shipMode.FreeShipMinAmount)">
                                                 <del class="text-warning">$ {{shipMode.Cost}}</del> <strong class="text-success">Costo Bonificado</strong>
                                            </template>
                                            <template v-else>
                                                $ {{shipMode.Cost}}
                                            </template>
                                        </template>
                                        <template v-else>
                                            $ {{shipMode.Cost}}
                                        </template>
                                      </p>
                                      <p>
                                          <template v-if="shipMode.OfficeCode">
                                                Retiro en Sucursal {{shipMode.OfficeName}}
                                          </template>
                                          <template v-else>
                                                <template v-if="shipMode.DelayMinDays && shipMode.DelayMaxDays">
                                                    Llega entre {{calculateDate(shipMode.DelayMinDays,shipMode)}} y el {{calculateDate(shipMode.DelayMaxDays,shipMode)}}.
                                                </template>
                                          </template>
                                      </p>
                                      <template v-if="shipMode.FreeShipMinAmount>0">
                                        <p>Envio gratis para compras mayores de $ {{shipMode.FreeShipMinAmount}}.</p>
                                      </template>
                                  </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="bordered form-row m-0 mb-2 px-2">
                        <h6 class="col-12 align-items-center d-flex mb-3"><i class="delivery-icon fas fa-truck fa-flip-horizontal fa-2x mr-3" style="font-size: 1.6em;"></i> {{tr('Reception Details')}}</h6>
                        <template v-if="activeDayByZone">
                            <div class="form-group col-12 col-md-6 mb-0" >
                                <label for="inputEmail4">{{tr('Day')}}</label>
                                <select class="custom-select mb-3" v-on:change="updateSelectValues('DelDay')">
                                    <option value="null" selected>{{tr('Select One')}}</option>
                                    <option value="1" v-if="daysByZone.Monday">{{tr('Monday')}}</option>
                                    <option value="2" v-if="daysByZone.Tuesday">{{tr('Tuesday')}}</option>
                                    <option value="3" v-if="daysByZone.Wednesday">{{tr('Wednesday')}}</option>
                                    <option value="4" v-if="daysByZone.Thursday">{{tr('Thursday')}}</option>
                                    <option value="5" v-if="daysByZone.Friday">{{tr('Friday')}}</option>
                                    <option value="6" v-if="daysByZone.Saturday" >{{tr('Saturday')}}</option>
                                    <option value="7" v-if="daysByZone.Sunday">{{tr('Sunday')}}</option>
                                </select>
                            </div>
                            <div class="col-md-6"></div>
                        </template>
                        <div class="form-row col-12 col-md-6 mb-0" :class="{'d-none':!showCommentSt}">
                           <label>Aclaraciones sobre el domicilio</label>
                            <textarea class="form-control" v-model="commentSt" rows="3" placeholder="Observaciones del domicilio, entre calles, otras referencias…" @change="buildComment" ></textarea>
                        </div>
                        <div class="form-group col-12 col-md-6 mb-0" :class="{'d-none':!showCommentSO}">
                           <label>Aclaraciones sobre el Pedido</label>
                           <textarea class="form-control" v-model="commentSO" rows="3" placeholder="Observaciones del pedido, encargado de recepción, etc…" @change="buildComment" ></textarea>
                        </div>
                    </div>
                  </div>
            </div>`;
    }
}

CarDeliveryListComponentB2C.registerComponent();